import * as React from "react"
import Avatar from "@mui/material/Avatar"
import Button from "@mui/material/Button"
import CssBaseline from "@mui/material/CssBaseline"
import TextField from "@mui/material/TextField"
import Link from "@mui/material/Link"
import { Box } from "@mui/material"
import LockOutlinedIcon from "@mui/icons-material/LockOutlined"
import Typography from "@mui/material/Typography"
import Container from "@mui/material/Container"
import { useAuthotizationContext } from "../auth/authotizationContext"
import { store } from "../../app/store"
import { useNavigate } from "react-router-dom"
import appRoutes from "../navigation/app.routes"
import Divider from "@mui/material/Divider"
import "./sign-in.scss"

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://pandaflow.io/">
        API Panda
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  )
}

export default function SignIn() {
  const auth = useAuthotizationContext()
  const navigate = useNavigate()

  const tokenState = store.getState().token

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)

    const email = data.get("email")
    const password = data.get("password")

    await auth.login(email?.toString(), password?.toString())
  }

  const renderRegisterLink = () => {
    if (!tokenState.canRegister) return <></>

    const navigateToRegister = () => {
      navigate(appRoutes.register)
    }

    return (
      <>
        <Divider />
        <div className="register-nav--container">
          <span>or</span>
          <a href={"#"} onClick={() => navigateToRegister()}>
            Register
          </a>
        </div>
      </>
    )
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </Button>
          {renderRegisterLink()}
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  )
}
