import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import { useIdleTimer } from "react-idle-timer"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import {
  createIsAccessTokenRefreshNeeded,
  logOut,
  registerAsync,
  selectToken,
  setCanRegister,
  toggleUnAuthorized,
  tryRefreshTokenAsync,
  updateUserState,
} from "./tokenHandlerSlice"
import appRoutes from "../navigation/app.routes"
import { useNavigate, useOutlet, useSearchParams } from "react-router-dom"

import { signInAsync } from "./tokenHandlerSlice"

import { store } from "../../app/store"
import { createConnectionAsync, setPublicToken } from "../connections/connectionSlice"

//TODO move to config
const idleTimeout = 1000 * 60 * 10
const tryRefreshTokenInterval = 1000 * 2880

const AuthorizationContext = createContext(null)

export const AuthorizationProvider = ({ children }) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [search] = useSearchParams()

  const getTokenState = () => store.getState().token

  const tokenState = getTokenState()
  const { isAuthenticated, user } = tokenState
  const [shouldTryRefreshToken, setShouldTryRefreshToken] =
    useState(isAuthenticated)

  const tryRefreshToken = () => {
    dispatch(tryRefreshTokenAsync())
  }

  const handleOnAction = () => setShouldTryRefreshToken(isAuthenticated)

  const handleOnIdle = () => {
    setShouldTryRefreshToken(false)

    if (isAuthenticated) {
      logOutAction()
    }
  }

  const instantConnection = () => {
    const [publicTokenParameter, registerParameter] = [`public_token`, `allow-register`]

    return {
      canCreate: () =>
        search.has(publicTokenParameter) && search.has(registerParameter),
      getPublicToken: () => search.get(publicTokenParameter),
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      const isAccessTokenRefreshNeeded = createIsAccessTokenRefreshNeeded(
        getTokenState().user,
      )

      const tokenHasTobeRefreshed =
        shouldTryRefreshToken && isAccessTokenRefreshNeeded

      if (!tokenHasTobeRefreshed) {
        return
      }

      if (tokenHasTobeRefreshed) {
        tryRefreshToken()
        return
      }

      if (user != null) {
        dispatch(updateUserState(user))
      }
    }, tryRefreshTokenInterval)

    if (instantConnection().canCreate()) {
      dispatch(setPublicToken(instantConnection().getPublicToken()))
      dispatch(setCanRegister())
    }

    if (!isAuthenticated) {
      if (location.pathname !== appRoutes.signIn)
        navigate(appRoutes.signIn)
    }else{
      if(store.getState().connections.publicToken){
        dispatch(createConnectionAsync({}))
      }
    }

    return () => clearInterval(interval)
  }, [])

  useIdleTimer({
    timeout: idleTimeout,
    onIdle: handleOnIdle,
    onAction: handleOnAction,
    debounce: 250,
  })

  const login = async (email: string, password: string) => {
    try {
      await dispatch(signInAsync({ email, password }))
      navigate(appRoutes.home)
    } catch (error) {}
  }

  const register = async (firstName: string | undefined, lastName: string | undefined, email: string | undefined, password: string | undefined) => {
    try {
      await dispatch(registerAsync({ firstName, lastName, email, password }))
      navigate(appRoutes.home)
    } catch (error) {}
  }

  const logOutAction = () => {
    dispatch(logOut())
    navigate(appRoutes.signIn)
  }

  const value = useMemo(
    () => ({
      user,
      login,
      logOut: logOutAction,
      register: register
    }),
    [user],
  )

  return (
    <AuthorizationContext.Provider value={value}>
      <UnAuthorizedListener logOut={logOutAction} />
      {children}
    </AuthorizationContext.Provider>
  )
}

const UnAuthorizedListener: React.FC<{ logOut: () => void }> = ({ logOut }) => {
  const { unAuthorized } = useAppSelector(selectToken)

  useEffect(() => {
    if (unAuthorized != null && unAuthorized != "triggered") return

    logOut()

    store.dispatch(toggleUnAuthorized())
  }, [unAuthorized])

  return <></>
}

export const useAuthotizationContext = () => {
  return useContext(AuthorizationContext)
}

export const AuthorizationLayout = () => {
  const outlet = useOutlet()

  return <AuthorizationProvider>{outlet}</AuthorizationProvider>
}
