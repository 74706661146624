import React from "react"
import ReactDOM from "react-dom/client"
import { Provider } from "react-redux"
import { store } from "./app/store"
import App from "./App"
import "./index.scss"
import { Events } from "./features/events/Events"

import ThemeProvider from "./theme/ThemeProvider"
import { CssBaseline } from "@mui/material"

ReactDOM.createRoot(document.getElementById("root")!).render(
  <Provider store={store}>
    <Events />
    <ThemeProvider>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </Provider>,
)
