import React from 'react';
import { Alert, Snackbar } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { hideEvent, selectEvents } from './eventsSlice';

export const Events = () => {

    const events = useAppSelector(selectEvents);
    const dispatch = useAppDispatch();

    const onClose = () => dispatch(hideEvent());

    return (
        <Snackbar
            open={events.open}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            onClose={onClose}>
            <Alert onClose={onClose} severity={events.event?.color} sx={{ width: '100%' }}>
                {events.event?.message}
            </Alert>
        </Snackbar>
    )
}