import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { Connection } from "./connection"
import { create, getList } from "./connectionsApi"
import { PageResponse } from "../common/pageResponse"
import { RootState } from "../../app/store"
import { ConnectionFilters } from "./connectionFilters"
import { ConnectionPagePayload } from "./connectionPagePayload"

export interface ConnectionsState {
  connections: Connection[]
  filters: ConnectionFilters
  page: number
  pageSize: number
  totalItems: number
  status: "idle" | "loading" | "failed",
  publicToken: string | null
}

let initialState: ConnectionsState = {
  connections: [],
  page: 0,
  pageSize: 10,
  totalItems: 0,
  status: "idle",
  filters: {
    search: "",
    integrationType: "all",
    category: "all",
  },
  publicToken: null
}

export const getListAsync = createAsyncThunk<
  any,
  ConnectionPagePayload,
  { state: RootState }
>("connection/list", async (payload: ConnectionPagePayload, store) => {
  const state = store.getState().connections

  await getList({ ...payload, ...state.filters })
})

export const createConnectionAsync = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("connection/create", async (_, store) => {
  const publicToken = store.getState().connections.publicToken

  await create({ publicToken: publicToken })
})

export const changePageAsync = createAsyncThunk<
  any,
  { page: number; pageSize: number },
  { state: RootState }
>(
  "connection/list/paging",
  async (payload: { page: number; pageSize: number }, store) => {
      const { page, pageSize } = store.getState().connections
      
      const pageRequest = { page, pageSize, ...payload }
      
      store.dispatch(updateConnectionsPaging(pageRequest))

    await store.dispatch(getListAsync(pageRequest))
  },
)

export const applyFiltersAsync = createAsyncThunk<
  any,
  ConnectionFilters,
  { state: RootState }
>("connection/list/filters", async (payload: ConnectionFilters, store) => {
  const partialPayload = {
    page: initialState.page,
    pageSize: initialState.pageSize,
  }

  const request = { ...partialPayload, ...payload }

  store.dispatch(updateFilters({ ...payload }))

  await store.dispatch(getListAsync(request))
})

const updateConnectionsStateAction = (
  state: ConnectionsState,
  action: PayloadAction<PageResponse<Connection> & ConnectionFilters>,
) => {
  state.connections = action.payload.items != null ? action.payload.items : []

  state.totalItems = action.payload.totalItems != null ? action.payload.totalItems : 0

  const { page, pageSize } = action.payload

  updateConnectionsPagingAction(state, {
    payload: { page: page != null ? page : 0, pageSize },
    type: null,
  })
}

const updateConnectionsPagingAction = (
  state: ConnectionsState,
  action: PayloadAction<{ page: number; pageSize: number }>,
) => {
  state.page = action.payload.page
  state.pageSize = action.payload.pageSize
}

const updateFiltersAction = (
  state: ConnectionsState,
  action: PayloadAction<ConnectionFilters>,
) => {
  state.filters = { ...state.filters, ...action.payload }
}

const setPublicTokenAction = (
  state: ConnectionsState,
  action: PayloadAction<string>,
) => {
  state.publicToken = action.payload
}

const resetPublicTokenAction = (
  state: ConnectionsState
) => {
  state.publicToken = null
}

export const connectionsSlice = createSlice({
  name: "connection",
  initialState,
  reducers: {
    updateFilters: updateFiltersAction,
    updateConnectionsState: updateConnectionsStateAction,
    updateConnectionsPaging: updateConnectionsPagingAction,
    setPublicToken: setPublicTokenAction,
    resetPublicToken: resetPublicTokenAction
  },
  extraReducers: (builder) => {
    builder
      .addCase(getListAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(getListAsync.fulfilled, (state) => {
        state.status = "idle"
      })
      .addCase(getListAsync.rejected, (state) => {
        state.status = "failed"
      })
  },
})

export const selectConnections = (state: RootState) => state.connections

export const {
  updateConnectionsState,
  updateConnectionsPaging,
  updateFilters,
  setPublicToken,
  resetPublicToken
} = connectionsSlice.actions

export default connectionsSlice.reducer
