import "./App.scss"
import { RouterProvider } from "react-router-dom"
import { HelmetProvider } from "react-helmet-async"
import { SidebarProvider } from "./contexts/SidebarContext"
import RoutesSetup from "./router"
import React from "react"

const app: React.FC = () => {
  return (
    <HelmetProvider>
      <SidebarProvider>
        <RouterProvider router={RoutesSetup} />
      </SidebarProvider>
    </HelmetProvider>
  )
}

export default app
