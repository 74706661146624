import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { getDetails, getList, getListConnections } from "./logsApi"
import { PageResponse } from "../common/pageResponse"
import { RootState } from "../../app/store"
import { Log } from "./log"
import { LogDetails } from "./logDetails"
import { LogFilters } from "./logFilters"
import { NameValue } from "../common/nameValue"
import { LogsPagePayload } from "./logsPagePayload"

export interface LogsState {
  connectionId: string | null
  connectionLogs: Log[]
  page: number
  pageSize: number
  totalItems: number
  status: "idle" | "loading" | "failed"
  logDetails: LogDetails | null
  connectionList: NameValue<string>[] | null
  filters: LogFilters
}

let initialState: LogsState = {
  connectionId: null,
  connectionLogs: [],
  page: 0,
  pageSize: 10,
  totalItems: 0,
  status: "idle",
  logDetails: null,
  filters: {
    connectionId: "all",
  },
  connectionList: null,
}

export const getListAsync = createAsyncThunk<any, LogsPagePayload>(
  "logs/list",
  async (payload: { connectionId: string; page: number; pageSize: number }) =>
    await getList(payload),
)

export const getConnectionListAsync = createAsyncThunk<any>(
  "logs/list/connections",
  async () => await getListConnections(),
)

export const getLogDetailsAsync = createAsyncThunk<
  any,
  { connectionId: string | null; requestTransactionId: string }
>(
  "logs/details",
  async (payload: {
    connectionId: string | null
    requestTransactionId: string
  }) => await getDetails(payload),
)

export const applyFiltersAsync = createAsyncThunk<
  any,
  Partial<LogFilters>,
  { state: RootState }
>("logs/list/filters", async (payload: LogFilters, store) => {
  const partialPayload = {
    page: initialState.page,
    pageSize: initialState.pageSize,
  }

  const request = { ...partialPayload, ...payload }

  store.dispatch(updateFilters({ ...payload }))

  await store.dispatch(getListAsync(request))
})

export const changePageAsync = createAsyncThunk<
  any,
  { page: number; pageSize: number },
  { state: RootState }
>(
  "logs/paging",
  async (
    payload: { page: number; pageSize: number },
    { dispatch, getState },
  ) => {
    dispatch(updateLogsPaging(payload))
    const state = getState()

    await dispatch(
      getListAsync({
        ...payload,
        connectionId: state.connectionLogs.connectionId,
      }),
    )
  },
)

const updateLogsStateAction = (
  state: LogsState,
  action: PayloadAction<PageResponse<Log>>,
) => {
  state.connectionLogs = action.payload.items

  state.totalItems = action.payload.totalItems

  const { page, pageSize } = action.payload

  updateLogsPagingAction(state, {
    payload: { page, pageSize },
    type: null,
  })
}

const updateLogsPagingAction = (
  state: LogsState,
  action: PayloadAction<{ page: number; pageSize: number }>,
) => {
  state.page = action.payload.page
  state.pageSize = action.payload.pageSize
}

const updateConnectionIdAction = (
  state: LogsState,
  action: PayloadAction<{ connectionId: string }>,
) => {
  state.connectionId = action.payload.connectionId
  state.filters = {
    ...state.filters,
    connectionId:
      action.payload.connectionId == null ? "all" : action.payload.connectionId,
  }
}

const updateFiltersAction = (
  state: LogsState,
  action: PayloadAction<LogFilters>,
) => {
  state.filters = { ...state.filters, ...action.payload }
}

export const connectionLogsSlice = createSlice({
  name: "logs",
  initialState,
  reducers: {
    updateLogsState: updateLogsStateAction,
    updateLogsPaging: updateLogsPagingAction,
    updateConnectionId: updateConnectionIdAction,
    updateFilters: updateFiltersAction,
    updateConnectionList: (
      state,
      action: PayloadAction<NameValue<string>[]>,
    ) => {
      state.connectionList = new Array(...action.payload)
    },
    updateLogDetails: (state, action: PayloadAction<LogDetails>) => {
      state.logDetails = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getListAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(getListAsync.fulfilled, (state) => {
        state.status = "idle"
      })
      .addCase(getListAsync.rejected, (state) => {
        state.status = "failed"
      })

    builder
      .addCase(getLogDetailsAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(getLogDetailsAsync.fulfilled, (state) => {
        state.status = "idle"
      })
      .addCase(getLogDetailsAsync.rejected, (state) => {
        state.status = "failed"
      })
  },
})

export const selectConnectionLogs = (state: RootState) => state.connectionLogs

export const {
  updateLogsState,
  updateLogsPaging,
  updateConnectionId,
  updateLogDetails,
  updateFilters,
  updateConnectionList,
} = connectionLogsSlice.actions

export default connectionLogsSlice.reducer
