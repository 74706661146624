import { store } from "../../app/store"
import { api } from "../common/api"
import {
  updateConnectionId,
  updateConnectionList,
  updateLogDetails,
  updateLogsState,
} from "./logsSlice"
import omitBy from "lodash/omitBy"
import isNil from "lodash/isNil"
import { LogsPagePayload } from "./logsPagePayload"

export const getList = async (request: Partial<LogsPagePayload>) => {
  const dispatch = store.dispatch

  request = {
    ...request,
    connectionId: request.connectionId === "all" ? null : request.connectionId,
  }

  const params = new URLSearchParams(omitBy(request, isNil)).toString()

  const connectionsResponse = await api.call(`api/admin/logs?${params}`)

  if (connectionsResponse != null) {
    await dispatch(updateLogsState({ ...connectionsResponse }))
    dispatch(updateConnectionId({ connectionId: request.connectionId }))
  }

  return connectionsResponse
}

export const getListConnections = async () => {
  const dispatch = store.dispatch

  const connectionsResponse = await api.call(`api/admin/logs/connections`)

  if (connectionsResponse != null) {
    await dispatch(updateConnectionList([...connectionsResponse]))
  }

  return connectionsResponse
}

export const getDetails = async (request: {
  connectionId: string | null
  requestTransactionId: string
}) => {
  const dispatch = store.dispatch

  const detailsResponse = await api.call(
    `api/admin/logs/${request.connectionId}/details/${request.requestTransactionId}`,
  )

  if (detailsResponse != null) {
    await dispatch(updateLogDetails({ ...detailsResponse }))
  }

  return detailsResponse
}
