import { Suspense, lazy } from "react"
import {
  Navigate,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom"

import SidebarLayout from "./layouts/SidebarLayout"

import SuspenseLoader from "./demo/components/SuspenseLoader"
import { AuthorizationLayout } from "./features/auth/authotizationContext"
import appRoutes from "./features/navigation/app.routes"
import SignIn from "./features/sign-in/sign-in"
import { ProtectedLayout } from "./features/layouts/protected"
import Register from "./features/register/register"

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  )

// Dashboards

const Tasks = Loader(lazy(() => import("./content/dashboards/Tasks")))

// Applications
const Connections = Loader(
  lazy(() => import("./content/applications/Connections")),
)
const ConnectionLogs = Loader(
  lazy(() => import("./content/applications/Logs")),
)

const UserSettings = Loader(
  lazy(() => import('./content/applications/Users/settings'))
);

// Status

const Status404 = Loader(lazy(() => import("./content/pages/Status/Status404")))
const Status500 = Loader(lazy(() => import("./content/pages/Status/Status500")))
const StatusComingSoon = Loader(
  lazy(() => import("./content/pages/Status/ComingSoon")),
)
const StatusMaintenance = Loader(
  lazy(() => import("./content/pages/Status/Maintenance")),
)

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<AuthorizationLayout />}>
      <Route path={appRoutes.signIn} element={<SignIn />} />
      <Route path={appRoutes.register} element={<Register />} />

      <Route path={appRoutes.home} element={<ProtectedLayout />}>
        <Route path="status">
          <Route path="404" element={<Status404 />} />
          <Route path="500" element={<Status500 />} />
          <Route path="maintenance" element={<StatusMaintenance />} />
          <Route path="coming-soon" element={<StatusComingSoon />} />
        </Route>
        <Route path="*" element={<Status404 />}></Route>
        <Route path="" element={<Navigate to="management" replace />}></Route>
        <Route path="dashboards" element={<SidebarLayout />}>
          <Route path="" element={<Tasks />} />
          <Route path="tasks" element={<Tasks />} />
        </Route>
        <Route path="management" element={<SidebarLayout />}>
          <Route path="" element={<Connections />} />
          <Route path="connections" element={<Connections />} />
          <Route path="connection-logs" element={<ConnectionLogs />} />
          <Route path="connection-logs/:id" element={<ConnectionLogs  />} />
          <Route path="profile" >
            <Route path="" element={<Navigate to={"settings"} replace />} />
            <Route path="settings" element={<UserSettings />} />
          </Route>
        </Route>
      </Route>
    </Route>,
  ),
)

export default router
