import { AlertColor } from "@mui/material/Alert"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../app/store"

export interface EventsState {
  open: boolean
  event: Event | null
}

export interface Event{
  message: string
  color: AlertColor
}

const initialState: EventsState = {
  event: null,
  open: false
}

export const eventsSlice = createSlice({
  name: "events",
  initialState,
  reducers: {
    showEvent: (state, action: PayloadAction<Event>) => {
      if(action.payload.message == null || action.payload.message == ""){
        return
      }
      
      state.event = action.payload
      state.open = true
    },
    hideEvent: (state) => {
      state.event = null
      state.open = false
    }
  }
})

export const { showEvent, hideEvent } = eventsSlice.actions

export const selectEvents = (state: RootState) => state.events;

export default eventsSlice.reducer