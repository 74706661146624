import { Link, Navigate, useOutlet } from "react-router-dom";
import { useAuthotizationContext } from "../../auth/authotizationContext";
import appRoutes from "../../navigation/app.routes";

export const ProtectedLayout = () => {
    const { user } = useAuthotizationContext();
    const outlet = useOutlet();
  
    if (!user) {
      return <Navigate to={appRoutes.signIn} />;
    }
  
    return (
      <div>
        {outlet}
      </div>
    )
  };