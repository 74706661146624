import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../app/store"
import { User } from "./user"
import { getProfile } from "./userProfile.api"

export interface UserProfileState extends Loadable {
  user: User | null
}

let initialState: UserProfileState = {
  user: null,
  status: "idle",
}

export const getProfileAsync = createAsyncThunk<any, never>(
  "logs/list",
  async () => await getProfile(),
)

export const connectionLogsSlice = createSlice({
  name: "logs",
  initialState,
  reducers: {
    updateUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProfileAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(getProfileAsync.fulfilled, (state) => {
        state.status = "idle"
      })
      .addCase(getProfileAsync.rejected, (state) => {
        state.status = "failed"
      })
  },
})

export const selectUserProfile = (state: RootState) => state.userProfile

export const { updateUser } = connectionLogsSlice.actions

export default connectionLogsSlice.reducer
