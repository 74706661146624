import { store } from "../../app/store"
import { api } from "../common/api"
import { ConnectionPagePayload } from "./connectionPagePayload"
import { getListAsync, resetPublicToken, updateConnectionsState } from "./connectionSlice"
import omitBy from "lodash/omitBy"
import isNil from "lodash/isNil"
import { ConnectionRequest } from "./connecionRequest"
import { resetCanRegister } from "../auth/tokenHandlerSlice"

export const getList = async (request: ConnectionPagePayload) => {
  const dispatch = store.dispatch

  request = {
    ...request,
    integrationType:
      request.integrationType === "all" ? null : request.integrationType,
    category: request.category === "all" ? null : request.category,
  }

  const params = new URLSearchParams(omitBy(request, isNil)).toString()

  const connectionsResponse = await api.call(`api/admin/connections?${params}`)

  if (connectionsResponse != null)
    await dispatch(updateConnectionsState(connectionsResponse))

  return connectionsResponse
}

export const create = async (request: ConnectionRequest) => {
  const dispatch = store.dispatch
  
  const connectionResponse = await api.call(`api/apps/shopify/attach-connection?public_token=${request.publicToken}`, api.POST());

  const {page, pageSize } = store.getState().connections

  if (connectionResponse != null && connectionResponse.authenticationUrl)
  {
      await dispatch(
        getListAsync(
          {
            page: page,
            pageSize: pageSize
          }
        )
      )

      window.open(connectionResponse.authenticationUrl, '_blank');

      dispatch(resetCanRegister())
      dispatch(resetPublicToken())
  }
}

export const generateTryIdLink = async (connectionId: string) => {
  const connectionResponse = await api.call(`api/v1/connection/try-connection/${connectionId}`);

  if (connectionResponse != null && connectionResponse.isSuccess)
  {
      window.open(connectionResponse.payload, '_blank');
  }
}