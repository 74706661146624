import { store } from "../../app/store"
import { api } from "../common/api"
import { createConnectionAsync } from "../connections/connectionSlice"
import { useAuthotizationContext } from "./authotizationContext"

import { updateUserState } from "./tokenHandlerSlice"

export const tryRefreshToken = async (refreshToken: string | undefined) => {
  const dispatch = store.dispatch

  const { logOut } = useAuthotizationContext()
  try {
    const userResponse = await api.call(
      "api/admin/users/refresh-token",
      api.POST(refreshToken),
    )

    if (userResponse != null) {
      dispatch(updateUserState(userResponse))
      return userResponse
    } else {
      dispatch(logOut())
    }
  } catch (error) {
    console.error(error)
  }
}

export const signIn = async (
  email: string | undefined,
  password: string | undefined
) => {
  const dispatch = store.dispatch

  const userResponse = await api.call(
    "api/admin/users/signin",
    api.POST({ email, password }),
  )

  if (userResponse != null){
    await dispatch(updateUserState(userResponse))

    if(store.getState().connections.publicToken){
      await dispatch(createConnectionAsync({}))
    }
  }

  return userResponse
}

export const register = async (
  firstName: string | undefined,
  lastName: string | undefined,
  email: string | undefined,
  password: string | undefined
) => {
  const dispatch = store.dispatch

  const userResponse = await api.call(
    "api/admin/users/signup",
    api.POST({ firstName: firstName, lastName: lastName, email, password }),
  )

  if (userResponse != null){
    await dispatch(updateUserState(userResponse))

    if(store.getState().connections.publicToken){
      await dispatch(createConnectionAsync({}))
    }
  }

  return userResponse
}
