import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { register, signIn, tryRefreshToken } from "./tokenHandlerAPI"
import { User } from "./user"
import moment from "moment"
import { RootState } from "../../app/store"

export interface TokenHandlerState {
  isAuthenticated: boolean
  isAccessTokenRefreshNeeded: boolean
  status: "idle" | "loading" | "failed"
  user?: User | null
  unAuthorized: UnAuthorized
  canRegister: boolean
}

const localStorageUserKey = "universalapi:user"

const user: User = JSON.parse(
  localStorage.getItem(localStorageUserKey) || "null",
)

export const createIsAccessTokenRefreshNeeded = (user: User) => {
  if (
    user == null ||
    user.accessToken == null ||
    user.accessToken.expiresAt == null
  )
    return false

  const accessTokenExpiresAt = moment.utc(user.accessToken.expiresAt)

  const accessTokenExpireAtWithMargin = accessTokenExpiresAt.add(-3, "minute")

  return moment.utc().isAfter(accessTokenExpireAtWithMargin)
}

const updateUserStateAction = (
  state: TokenHandlerState,
  action: PayloadAction<User>,
) => {
  state.isAuthenticated = action.payload != null

  state.isAccessTokenRefreshNeeded = createIsAccessTokenRefreshNeeded(
    action.payload,
  )

  state.user = action.payload

  localStorage.setItem(localStorageUserKey, JSON.stringify(action.payload))
}

const setCanRegisterAction = (state: TokenHandlerState) => {
  state.canRegister = true
}

const resetCanRegisterAction = (state: TokenHandlerState) => {
  state.canRegister = false
}

type UnAuthorized = "idle" | "triggered"

let initialState: TokenHandlerState = {
  isAuthenticated: false,
  isAccessTokenRefreshNeeded: false,
  status: "idle",
  user: user,
  unAuthorized: "idle",
  canRegister: false,
}

if (user != null) {
  updateUserStateAction(initialState, { payload: user, type: "" })
}

export const tryRefreshTokenAsync = createAsyncThunk<
  User,
  void,
  { state: RootState }
>(
  "tokenHandler/tryRefreshToken",
  async (_, { getState }) =>
    await tryRefreshToken(getState().token.user?.refreshToken.value),
)

export const signInAsync = createAsyncThunk<
  any,
  { email: string | undefined; password: string | undefined }
>(
  "tokenHandler/signIn",
  async (payload: {
    email: string | undefined
    password: string | undefined
    publicKey: string | undefined
  }) => await signIn(payload.email, payload.password),
)

export const registerAsync = createAsyncThunk<
  any,
  { firstName: string | undefined, lastName: string | undefined, email: string | undefined; password: string | undefined}
>(
  "tokenHandler/register",
  async (payload: {
    firstName: string | undefined
    lastName: string | undefined
    email: string | undefined
    password: string | undefined
  }) => await register(payload.firstName, payload.lastName, payload.email, payload.password),
)

const toggleUnAuthorizedState = (value: UnAuthorized) => {
  switch (value) {
    case "idle":
      return "triggered"
    case "triggered":
      return "idle"
  }
}

export const tokenHandlerSlice = createSlice({
  name: "tokenHandler",
  initialState,
  reducers: {
    updateUserState: updateUserStateAction,
    setCanRegister: setCanRegisterAction,
    resetCanRegister: resetCanRegisterAction,
    logOut: (state) => {
      state.isAccessTokenRefreshNeeded = false
      state.isAuthenticated = false

      state.user = null

      localStorage.removeItem(localStorageUserKey)
    },
    toggleUnAuthorized: (state) => {
      state.unAuthorized = toggleUnAuthorizedState(state.unAuthorized)
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(tryRefreshTokenAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(tryRefreshTokenAsync.fulfilled, (state) => {
        state.status = "idle"
      })
      .addCase(tryRefreshTokenAsync.rejected, (state) => {
        state.status = "failed"
      })

    builder
      .addCase(signInAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(signInAsync.fulfilled, (state) => {
        state.status = "idle"
      })
      .addCase(signInAsync.rejected, (state) => {
        state.status = "failed"
      })

    builder
      .addCase(registerAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(registerAsync.fulfilled, (state) => {
        state.status = "idle"
      })
      .addCase(registerAsync.rejected, (state) => {
        state.status = "failed"
      })
  },
})

export const selectToken = (state: RootState) => state.token

export const { updateUserState, logOut, toggleUnAuthorized, setCanRegister, resetCanRegister } =
  tokenHandlerSlice.actions

export default tokenHandlerSlice.reducer
