import { store } from "../../app/store";

import { toggleUnAuthorized } from "../auth/tokenHandlerSlice";
import { showEvent } from "../events/eventsSlice";

const baseOptions = {
  mode: "cors",
  cache: "no-cache",
  credentials: "same-origin",
  redirect: "follow",
  referrerPolicy: "no-referrer"
}

const JSON_HEADERS = () => {
  const token = store.getState().token.user?.accessToken.value;

  return {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + token
  };
};

const FORM_HEADERS = () => {
  const token = store.getState().token.user?.accessToken.value;

  return {
    Accept: "application/json",
    "Content-Type": "application/octet-stream",
    Authorization: "Bearer " + token
  };
};

const GET = () => {
  return {
    method: "GET",
    headers: JSON_HEADERS()
  };
};
const POST = (data?: any | null) => {
  return {
    method: "POST",
    headers: JSON_HEADERS(),
    body: JSON.stringify(data)
  };
};
const PUT = (data: any) => {
  return {
    method: "PUT",
    headers: JSON_HEADERS(),
    body: JSON.stringify(data)
  };
};

const DELETE = () => {
  return {
    method: "DELETE",
    headers: JSON_HEADERS()
  };
};

function handleError(response: any) {
   store.dispatch(showEvent({message: response.errorMessage, color: "error"}));
  return null;
}

function handleResponse(response: any) {
  return new Promise((resolve, reject) => {
    let contentType = response.headers.get("content-type");
    let isJson = contentType && contentType.includes("application/json");
    if (response.ok) {
      // return json if it was returned in the response
      if (isJson) {
        response.json().then((json: any) => resolve(json));
      } else {
        resolve(response);
      }
    } else {
      if (response.status === 401) {
        store.dispatch(toggleUnAuthorized())
        
        return;
      }
      if (isJson) {
        response.json().then((json: any) => {
          if (json && json.errorDetails) {
            console.error("REQUEST ERROR:", json.errorDetails);
          }
          reject(json);
        });
      } else {
        response.text().then((text: string) => {
          reject({ message: text });
        });
      }
    }
  }).catch(handleError);
}

export const api = {
  JSON_HEADERS,
  FORM_HEADERS,
  GET,
  POST,
  PUT,
  DELETE,
  call: (url: string, headers = GET()) => {
    return fetch(import.meta.env.VITE_API_HOST + url, {...baseOptions, ...headers} as any).then(handleResponse, handleError) as Promise<any>
  }
    
};
