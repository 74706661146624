import { store } from "../../app/store"
import { api } from "../common/api"
import { updateUser } from "./userProfileSlice"

export const getProfile = async () => {
    const dispatch = store.dispatch
  
    const userProfileResponse = await api.call(`api/admin/user/profile`)
  
    if (userProfileResponse != null) {
      await dispatch(updateUser(userProfileResponse))
    }
  
    return userProfileResponse
  }