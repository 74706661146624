import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit"
import tokenHandlerReducer from "../features/auth/tokenHandlerSlice"
import eventsSliceReducer from "../features/events/eventsSlice"
import connectionSliceReducer from "../features/connections/connectionSlice"
import connectionLogsSliceReducer from "../features/logging/logsSlice"
import userProfileSliceReducer from "../features/userProfile/userProfileSlice"

export const store = configureStore({
  reducer: {
    token: tokenHandlerReducer,
    events: eventsSliceReducer,
    connections: connectionSliceReducer,
    connectionLogs: connectionLogsSliceReducer,
    userProfile: userProfileSliceReducer
  }
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
